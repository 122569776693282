import React, { useEffect, useCallback } from 'react';

import useSubmitClaim from './useSubmitClaim';
import CreateClaim from '../../parcel-finder/claims/CreateClaim';
import useSearchShipment from './useSearchShipment';
import { trackEvent } from '../../../utils/googleAnalytics';
import machine from './stateMachine';
import { useClaimsShopSettings } from '../../parcel-finder/ClaimsShopSettingsContext';

function CreateClaimContainer({ onClose, onClaimSubmitted }) {
  const { claim, isSubmitting, isClaimsAutomated, changeClaim, resetClaim, submitClaim } = useSubmitClaim();
  const { isSearching, nonClaimableMessage, resetShipmentSearch, loadShipmentByTrackingId } = useSearchShipment({
    claim,
    changeClaim,
    resetClaim,
  });

  useEffect(() => {
    trackClaimAction('Claim initiated');
  }, []);

  const onCloseHandler = useCallback(
    (step) => {
      trackClaimAction(`Claim exit: ${step}`);
      onClose();
    },
    [onClose]
  );

  const onSubmitHandler = useCallback(async () => {
    const { success } = await submitClaim();

    if (success) {
      trackClaimAction('Claim submitted');
      onClaimSubmitted();
      onClose();
    }
  }, [submitClaim, onClose, onClaimSubmitted]);

  const isDataLoaded = !!claim;
  const { hasNon7sCarriers: isNon7sCustomer } = useClaimsShopSettings();
  const isCashOnDeliveryDisabled = !isNon7sCustomer;
  const cashOnDeliveryWarningMessage = getWarningMessage({ isDataLoaded, isNon7sCustomer });

  return (
    <CreateClaim
      claim={claim}
      resetClaim={resetClaim}
      machine={machine}
      onChange={changeClaim}
      onLoadShipmentByTrackingId={loadShipmentByTrackingId}
      onResetShipmentSearch={resetShipmentSearch}
      onClose={onCloseHandler}
      onSubmit={onSubmitHandler}
      isSubmitting={isSubmitting || isSearching}
      isDisabled={isSearching}
      nonClaimableMessage={nonClaimableMessage}
      isClaimsAutomated={isClaimsAutomated}
      cashOnDeliveryWarningMessage={cashOnDeliveryWarningMessage}
      isCashOnDeliveryDisabled={isCashOnDeliveryDisabled}
    />
  );
}

function getWarningMessage({ isDataLoaded, isNon7sCustomer }) {
  if (!isDataLoaded) {
    return 'Loading...';
  }

  if (!isNon7sCustomer) {
    return 'CASH_ON_DELIVERY_WARNING_NOT_CLAIM_ME_CUSTOMER';
  }

  return null;
}

function trackClaimAction(action) {
  return trackEvent({
    category: 'claims-center',
    action,
  });
}

export default React.memo(CreateClaimContainer);
