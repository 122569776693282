import React, { createContext, useContext } from 'react';

/**
 * @typedef ClaimsShopSettings
 * @property {boolean} hasNon7sCarriers
 * @property {boolean} isEasyDOREnabled
 */

/**
 * @type {ClaimsShopSettings}
 */
const defaultValue = {
  hasNon7sCarriers: false,
  isEasyDOREnabled: false,
};

/**
 * @type {React.Context<ClaimsShopSettings>}
 */
const ClaimsShopSettingsContext = createContext(defaultValue);

/**
 * @param {Object} props
 * @param {Partial<ClaimsShopSettings>} props.value
 * @param {React.ReactNode} props.children
 */
export function ClaimsShopSettingsContextProvider({ value, children }) {
  return (
    <ClaimsShopSettingsContext.Provider value={{ ...defaultValue, ...value }}>
      {children}
    </ClaimsShopSettingsContext.Provider>
  );
}

/**
 * @return {ClaimsShopSettings}
 */
export function useClaimsShopSettings() {
  return useContext(ClaimsShopSettingsContext);
}
