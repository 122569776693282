import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useNotifications from '../../../hooks/useNotifications';
import useDispatchCallback from '../../../hooks/useDispatchCallback';
import { cancelClaim, updateClaim } from '../../../reducers/claimReducer';
import submitClaim from '../../../actions/submitClaim';
import CreateClaim from './CreateClaim';
import { trackEvent } from '../../../utils/googleAnalytics';
import usePermissionAccess from '../../../hooks/usePermissionAccess';
import { CLAIMS_AUTOMATED } from '../../../firewall/permissions';
import machine from '../../claims-center/submit-claims/stateMachine';

function CreateClaimContainer() {
  const { showNotice, showError } = useNotifications();

  const isClaimsAutomated = usePermissionAccess(CLAIMS_AUTOMATED);
  const claim = useSelector((state) => state.claim.claim);

  const isSubmitting = useSelector((state) => state.claim.isSubmitting);

  const onChange = useDispatchCallback((values) => updateClaim(values));

  const dispatch = useDispatch();

  useEffect(() => {
    trackClaimAction('Claim initiated');
  }, []);

  const onClose = useCallback(
    (step) => {
      trackClaimAction(`Claim exit: ${step}`);
      dispatch(cancelClaim());
    },
    [dispatch]
  );

  const onSubmit = useCallback(() => {
    trackClaimAction('Claim submitted');

    dispatch(submitClaim({ isClaimsAutomated })).then((result) => {
      if (result.success) {
        showNotice({
          type: 'success',
          message: 'CLAIMS_SUCCESSFULLY_SUBMITTED_NOTICE',
        });
      } else {
        showError();
      }
    });
  }, [dispatch, showNotice, showError, isClaimsAutomated]);

  const cashOnDeliveryWarningCode = claim?.claimableWarnings?.['cash_on_delivery'];
  const cashOnDeliveryWarningMessage = getCashOnDeliveryWarningMessage(cashOnDeliveryWarningCode);
  const isCashOnDeliveryDisabled = !!cashOnDeliveryWarningMessage;

  return (
    <CreateClaim
      claim={claim}
      machine={machine}
      onChange={onChange}
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      isClaimsAutomated={isClaimsAutomated}
      cashOnDeliveryWarningMessage={cashOnDeliveryWarningMessage}
      isCashOnDeliveryDisabled={isCashOnDeliveryDisabled}
    />
  );
}

function trackClaimAction(action) {
  return trackEvent({
    category: 'parcel-finder',
    action,
  });
}

/* eslint-disable camelcase */
const cashOnDeliveryWarningMessages = {
  not_claims_me_customer: 'CASH_ON_DELIVERY_WARNING_NOT_CLAIM_ME_CUSTOMER',
  not_outbound_shipment: 'CASH_ON_DELIVERY_WARNING_NOT_OUTBOUND_SHIPMENT',
  is_7s_shipment: 'CASH_ON_DELIVERY_WARNING_7S_SHIPMENT',
};
/* eslint-enable camelcase */

function getCashOnDeliveryWarningMessage(warningCode) {
  return cashOnDeliveryWarningMessages[warningCode] || null;
}

export default React.memo(CreateClaimContainer);
