import React from 'react';
import Switch from '../Wizard/Switch';
import ParcelFinderContainer from '../parcel-finder/ParcelFinderContainer';
import ClaimsCenterOverview from './ClaimsCenterOverview';
import ClaimsCenterEmptyOverview from './ClaimsCenterEmptyOverview';
import Loader from '../common/Loader/Loader';
import Heading2 from '../common/Heading2';
import useModal from '../modal/useModal';
import useClaimsStats from './useClaimsStats';
import SubmitClaimPanel from './submit-claims/SubmitClaimPanel';
import useClaimsCenterSearchOptions from './useClaimsCenterSearchOptions';

function getSelected(claimsStats, loading, error) {
  if (loading) {
    return 'loading';
  }

  if (error) {
    return 'error';
  }

  if (claimsStats?.totalCount > 0) {
    return 'overview';
  }

  return 'empty';
}

function ClaimsCenterPage(props) {
  const { claimsStats, statusCounts, isStatsLoading, statsError, reloadClaimsStats } = useClaimsStats();
  const { searchFilters, setSearchFilters, sortBy, setSortBy, sortDir, setSortDir } = useClaimsCenterSearchOptions();
  const [isSubmitClaimPanelOpen, showSidePanel, closeSidePanel] = useModal(false);

  return (
    <ParcelFinderContainer {...props}>
      <Switch selected={getSelected(claimsStats, isStatsLoading, statsError)}>
        <Loader key="loading" />
        <ClaimsCenterEmptyOverview key="empty" showSidePanel={showSidePanel} />
        <ClaimsCenterOverview
          key="overview"
          claimsStats={claimsStats}
          statusCounts={statusCounts}
          isStatsLoading={isStatsLoading}
          statsError={statsError}
          reloadClaimsStats={reloadClaimsStats}
          showSubmitClaimPanel={showSidePanel}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDir={sortDir}
          setSortDir={setSortDir}
        />
        <Heading2 key="error">Error loading claims</Heading2>
      </Switch>
      <SubmitClaimPanel
        isOpen={!!isSubmitClaimPanelOpen}
        onClose={closeSidePanel}
        onClaimSubmitted={reloadClaimsStats}
      />
    </ParcelFinderContainer>
  );
}

export default ClaimsCenterPage;
