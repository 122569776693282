import React, { useCallback, useEffect } from 'react';
import * as yup from 'yup';

import Select from '../../../common2/Select';
import ColumnsLayout from '../../../common/layout/ColumnsLayout';
import CarrierSelect from './CarrierSelect';
import DateInput from '../../../common2/DateInput';
import Locale from '../../../Translate/Locale';
import SidePanelContentSection from '../../../common2/side-panel/SidePanelContentSection';
import { useClaimCarrierSelectOptions } from './useClaimCarrierSelectOptions';
import Switch from '../../../Wizard/Switch';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import { useYupValidation } from '../../../../hooks/useYupValidation';
import useOnFormChange from '../../../../hooks/useOnFormChange';
import { getClaimableWarning, isClaimWithMissingTrackingData } from '../claimsHelpers';
import Text from '../../../common/Text/Text';
import './DeadlineShippingInfoStep.scss';
import ExternalLink from '../../../common2/links/ExternalLink';

const shipmentTypeOptions = [
  { value: 'outbound', label: 'Outbound' },
  { value: 'return', label: 'Return' },
  { value: 'undeliverable_or_postal_return', label: 'Undeliverable / Postal Return' },
];

const schema = yup.object().shape({
  type: yup.string().required(),
  shipmentType: yup.string().required().label('Shipment type'),
  carrierCode: yup.string().required().label('Carrier'),
  carrierCountryCode: yup.string().required().label('Carrier country'),
  firstHubScanDate: yup
    .date()
    .nullable()
    .label('First hub scan date')
    .when(['type', '$isNon7sCarrier'], {
      is: (type, isNon7sCarrier) => type !== 'investigation' || !isNon7sCarrier,
      then: (schema) => schema.required(),
    }),
  postalReturnDate: yup
    .date()
    .nullable()
    .when(['type', 'shipmentType'], {
      is: (type, shipmentType) => type === 'investigation' && shipmentType === 'undeliverable_or_postal_return',
      then: (schema) => schema.required(),
    })
    .label('Postal return date'),
  deliveryDate: yup
    .date()
    .nullable()
    .when(['type'], {
      is: (type) => type !== 'investigation',
      then: (schema) => schema.required(),
    })
    .label('Delivery date'),
  postalReturnDeliveryDate: yup.date().nullable().label('Delivery date'),
});

function DeadlineShippingInfoStep(props) {
  const { claim, carriers, as: Container, onNext, onBack, onChange, isManual, ...restProps } = props;
  const { type, shipmentType, carrierCode, carrierCountryCode } = claim;

  const isCashOnDeliveryClaim = type === 'cash_on_delivery';

  const { carrierOptions, carrierCountryOptions, isNon7sCarrier } = useClaimCarrierSelectOptions({
    carrierCode: claim.carrierCode,
    carrierCountryCode: claim.carrierCountryCode,
    carriers,
    type,
  });

  const onShipmentTypeChange = useCallback(
    (shipmentType) =>
      onChange({
        shipmentType,
        isReturnShipment: shipmentType === 'return',
        isDelivered: false,
        // reset all dates when changing shipment type to avoid hidden dates which aren't relevant anymore
        firstHubScanDate: null,
        postalReturnDate: null,
        deliveryDate: null,
        postalReturnDeliveryDate: null,
        declarationOfRecipient: [], // if it was already filled on another step, it's not relevant anymore
      }),
    [onChange]
  );

  useEffect(() => {
    if (isCashOnDeliveryClaim) {
      onShipmentTypeChange('outbound');
    }
  }, [isCashOnDeliveryClaim, onShipmentTypeChange]);

  const onInputChange = useOnFormChange(onChange);

  const onDeliveryDateChange = useCallback((deliveryDate) => onChange({ deliveryDate, isDelivered: !!deliveryDate }), [
    onChange,
  ]);

  const onPostalReturnDeliveryDateChange = useCallback(
    (postalReturnDeliveryDate) => onChange({ postalReturnDeliveryDate, isDelivered: !!postalReturnDeliveryDate }),
    [onChange]
  );

  const isTrackingDataMissing = isClaimWithMissingTrackingData(claim);

  const context = { isNon7sCarrier };
  const { validate, isFieldRequired, errors } = useYupValidation(schema);

  const onClickNext = () => {
    if (validate(claim, context)) {
      onNext();
    }
  };

  const claimableWarning = getClaimableWarning(claim);

  if (claimableWarning && claimableWarning !== 'no_required_tracking_data') {
    console.error(`Unexpected claimable warning "${claimableWarning}"`);
    return null;
  }

  const getDateInputProps = (name) => ({
    name,
    value: claim[name],
    error: errors[name],
    noErrorTranslation: true,
    isRequired: isFieldRequired(name, { value: claim, context }),
    onChange: onInputChange,
  });

  const selectedShipmentTypeAndFlow = `${
    type === 'investigation' ? 'investigation' : 'damage_or_missing_item'
  }_${shipmentType}`;

  const moreInformationLink =
    // eslint-disable-next-line max-len
    "https://support.portal.sevensenders.com/support/solutions/articles/15000030711-how-to-submit-a-claim#If-Seven-Senders'-tracking-is-incomplete ";

  return (
    <Container {...restProps} onNext={onClickNext} onBack={onBack}>
      {isTrackingDataMissing && (
        <ClaimStepClosableMessage intent="warning">
          <div styleName="inline">
            <Text>
              <Locale>CLAIMS_MISSING_TRACKING_DATA</Locale>&nbsp;
            </Text>
            <ExternalLink to={moreInformationLink}>
              <Locale>LINK_FOR_MISSING_TRACKING_DATA_MORE_INFORMATION</Locale>
            </ExternalLink>
          </div>
        </ClaimStepClosableMessage>
      )}
      {!isTrackingDataMissing && (
        <ClaimStepClosableMessage intent="danger">
          <Locale>CLAIMS_NO_SHIPMENT_FOUND_ENTER_DEADLINE_INFO</Locale>{' '}
        </ClaimStepClosableMessage>
      )}
      <SidePanelContentSection heading="Deadline check">
        <ColumnsLayout>
          <Select
            label="Shipment type"
            placeholder="Select shipment type"
            value={shipmentType}
            options={shipmentTypeOptions}
            onChange={onShipmentTypeChange}
            error={errors.shipmentType}
            isDisabled={isCashOnDeliveryClaim || isTrackingDataMissing}
            isRequired
          />
          <div />
          <CarrierSelect
            carriers={carriers}
            carrierCode={carrierCode}
            carrierCountryCode={carrierCountryCode}
            carrierOptions={carrierOptions}
            carrierCountryOptions={carrierCountryOptions}
            errors={errors}
            isDisabled={isTrackingDataMissing}
            onChange={onChange}
          />
          <DateInput {...getDateInputProps('firstHubScanDate')} label="First hub scan date" />
          <Switch selected={selectedShipmentTypeAndFlow}>
            <DateInput
              key="investigation_outbound"
              {...getDateInputProps('deliveryDate')}
              label="Delivery date"
              onChange={onDeliveryDateChange}
            />
            <DateInput
              key="investigation_return"
              {...getDateInputProps('deliveryDate')}
              label="Delivery date at carrier's hub"
              onChange={onDeliveryDateChange}
            />
            <React.Fragment key="investigation_undeliverable_or_postal_return">
              <DateInput {...getDateInputProps('postalReturnDate')} label="Postal return date" />
              <DateInput
                {...getDateInputProps('postalReturnDeliveryDate')}
                label="Delivery date at carrier's hub"
                onChange={onPostalReturnDeliveryDateChange}
              />
            </React.Fragment>
            <DateInput
              key="damage_or_missing_item_outbound"
              {...getDateInputProps('deliveryDate')}
              label="Delivery date"
              onChange={onDeliveryDateChange}
            />
            <DateInput
              key="damage_or_missing_item_return"
              {...getDateInputProps('deliveryDate')}
              label="Delivery date"
              helpText="WAREHOUSE_DELIVERY_AS_DELIVERY_DATE_HELP_TEXT"
              onChange={onDeliveryDateChange}
            />
            <DateInput
              key="damage_or_missing_item_undeliverable_or_postal_return"
              {...getDateInputProps('deliveryDate')}
              helpText="WAREHOUSE_DELIVERY_AS_DELIVERY_DATE_HELP_TEXT"
              label="Delivery date"
              onChange={onDeliveryDateChange}
            />
          </Switch>
        </ColumnsLayout>
      </SidePanelContentSection>
    </Container>
  );
}

export default React.memo(DeadlineShippingInfoStep);
