import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';

import ButtonDropdown from '../../../common2/ButtonDropdown/ButtonDropdown';
import MenuItem from '../../../common2/DropdownButton/MenuItem';
import Locale from '../../../Translate/Locale';
import LinkIcon from '../../../../icons/link.svg';
import container from '../../../../container';
import DORSigningLinkModal from './DORSigningLinkModal';
import { useSelectedShopId } from '../../../../hooks/useSelectedShop';

function DORSigningLinkButton(props) {
  const { languages, shipmentId } = props;
  const shopId = useSelectedShopId();
  const [selectedLanguage, setSelectedLanguage] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);

  const mutation = useMutation({
    mutationKey: ['createDorSigningLink', shopId, shipmentId, selectedLanguage],
    mutationFn: (language) => container.touchpointsApi.createDorSigningLink(shopId, shipmentId, language),
  });

  const handleLanguageSelection = (language) => {
    if (language !== selectedLanguage) {
      setSelectedLanguage(language);
      mutation.mutate(language);
    }

    setShowModal(true);
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <ButtonDropdown icon={LinkIcon} text={'DOR signing link'}>
        {languages.map(({ key: language, value: label }) => (
          <MenuItem key={language} onClick={() => handleLanguageSelection(language)}>
            <Locale>{label}</Locale>
          </MenuItem>
        ))}
      </ButtonDropdown>
      <DORSigningLinkModal
        show={showModal}
        onClose={onCloseModal}
        signingLink={mutation.data?.signingLink}
        isError={mutation.isError}
      />
    </>
  );
}

DORSigningLinkButton.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })),
  shipmentId: PropTypes.number,
};

export default React.memo(DORSigningLinkButton);
