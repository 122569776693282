import React from 'react';
import { useQuery } from '@tanstack/react-query';

import OutsideOfDeadlineStep from '../common/OutsideOfDeadlineStep';
import container from '../../../../container';
import Loader from '../../../common/Loader';
import LoadingError from '../../../common/LoadingError';

function ManualDeadlineCheckStep(props) {
  const { shopId, claim, as: StepContainer, onNext, onChange } = props;

  const { data: manualDeadline, isFetching, isFetchedAfterMount, isError } = useQuery({
    queryKey: ['claimManualDeadline', shopId], // claim is not included intentionally to not re-fetch on claim change
    queryFn: () =>
      container.touchpointsApi
        .postClaimsDeadlines(shopId, {
          claimType: claim.type,
          ...claim,
        })
        .then((data) => data.deadline),
  });

  if (isFetchedAfterMount && (manualDeadline === null || manualDeadline?.outcome === 'within')) {
    // we need to reset deadlines form if user goes back and changes something which makes new deadline to pass
    onChange({
      outsideOfDeadlineReason: null,
      deadlineSupportingDocuments: [],
    });

    // we don't want navigation history to remember this step because when deadlines within we just skip it
    onNext({ replace: true });
    return null;
  }

  if (isFetching) {
    return (
      <StepContainer {...props}>
        <Loader />
      </StepContainer>
    );
  }

  if (isError) {
    return (
      <StepContainer {...props}>
        <LoadingError />
      </StepContainer>
    );
  }

  return <OutsideOfDeadlineStep manualDeadline={manualDeadline} {...props} />;
}

export default React.memo(ManualDeadlineCheckStep);
