/* eslint-disable camelcase */
import { useState, useCallback } from 'react';

import useNotifications from '../../../hooks/useNotifications';
import { createClaimFromShipment, isMissingDeliveryDateAllowed } from '../../parcel-finder/claims/claimsHelpers';
import container from '../../../container';

const nonClaimableMessages = {
  claim_exists_for_shipment_id: 'CLAIM_ALREADY_EXISTS_FOR_SHIPMENT',
  no_dach_permission: 'CLAIM_NO_DACH_PERMISSION',
  no_hub_scan_date: 'CLAIM_NO_HUB_SCAN_DATE',
  claims_not_enabled: 'CLAIM_NOT_ENABLED',
  no_delivery_date: 'CLAIMS_NO_DELIVERY_DATE',
  is_7s_shipment: 'CLAIMS_CENTER_CASH_ON_DELIVERY_IS_7S_SHIPMENT',
  not_outbound_shipment: 'CLAIMS_CENTER_CASH_ON_DELIVERY_IS_NOT_OUTBOUND_SHIPMENT',
};

function useSearchShipment({ claim, changeClaim = () => {}, resetClaim = () => {} }) {
  const { showError } = useNotifications();
  const [isSearching, setIsSearching] = useState(false);
  const [nonClaimableMessage, setNonClaimableMessage] = useState();

  const resetShipmentSearch = useCallback(() => {
    resetClaim();
    setNonClaimableMessage();
  }, [resetClaim, setNonClaimableMessage]);

  const loadShipmentByTrackingId = useCallback(
    async (trackingCode) => {
      setIsSearching(true);
      setNonClaimableMessage();

      try {
        const response = await container.touchpointsApi.getShipmentWithClaimableInfoByTrackingCode(trackingCode);
        if (response.length > 0) {
          const { isClaimAllowed, messageCode } = getClaimableState({
            shipment: response[0],
            claimType: claim.type,
            claim,
          });

          if (isClaimAllowed) {
            const claimFromShipment = createClaimFromShipment(response[0]);
            changeClaim(claimFromShipment);
          }

          setIsSearching(false);
          setNonClaimableMessage(nonClaimableMessages[messageCode]);

          return { isClaimAllowed };
        }

        setIsSearching(false);

        return { isClaimAllowed: true };
      } catch (err) {
        setIsSearching(false);
        showError();

        return { isClaimAllowed: false };
      }
    },
    [changeClaim, resetClaim, setNonClaimableMessage, showError, claim.type, claim]
  );

  return {
    isSearching,
    nonClaimableMessage,
    resetShipmentSearch,
    loadShipmentByTrackingId,
  };
}

function getClaimableState({ shipment, claimType }) {
  const { claimable, nonClaimableReasonCode, deliveryDate, postalReturnDeliveryDate, claimableWarnings } = shipment;

  if (!claimable) {
    return { isClaimAllowed: false, messageCode: nonClaimableReasonCode };
  }

  if (
    claimType !== 'investigation' &&
    !deliveryDate &&
    !postalReturnDeliveryDate &&
    !isMissingDeliveryDateAllowed(shipment)
  ) {
    return { isClaimAllowed: false, messageCode: 'no_delivery_date' };
  }

  if (claimType === 'cash_on_delivery') {
    const cashOnDeliveryWarningCode = claimableWarnings?.['cash_on_delivery'];
    if (cashOnDeliveryWarningCode && cashOnDeliveryWarningCode !== 'no_required_tracking_data') {
      return { isClaimAllowed: false, messageCode: cashOnDeliveryWarningCode };
    }
  }

  return { isClaimAllowed: true, messageCode: null };
}

export default useSearchShipment;
