import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Text from '../Text';
import useNotifications from '../../../hooks/useNotifications';

import DuplicateIcon from '../../../icons/duplicate2.svg';

import './LinkPreview.scss';

function LinkPreview(props) {
  const { children, feedbackMessage = 'The URL has been copied', showCopyButton = true } = props;
  const { showNotice } = useNotifications();

  const onButtonClick = useCallback(() => showNotice({ message: feedbackMessage, type: 'info' }), [
    showNotice,
    feedbackMessage,
  ]);

  return (
    <div styleName="container">
      <Text styleName="link">{children}</Text>
      {showCopyButton && (
        <CopyToClipboard text={children}>
          <button styleName="copy-button" aria-label="Copy" onClick={onButtonClick}>
            <DuplicateIcon styleName="icon" />
          </button>
        </CopyToClipboard>
      )}
    </div>
  );
}

LinkPreview.propTypes = {
  children: PropTypes.string,
  feedbackMessage: PropTypes.string,
};

export default React.memo(LinkPreview);
