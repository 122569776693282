import React from 'react';
import PropTypes from 'prop-types';
import DORSigningLinkButton from './DORSigningLinkButton';
import DownloadDORButton from './DownloadDORButton';

const languageToLabel = {
  en: 'English',
  de: 'German',
  fr: 'French',
  es: 'Spanish',
  nl: 'Dutch',
  it: 'Italian',
};

function DORButtonDropdown(props) {
  const { shipmentId, trackingCode, availableDORLanguages, type } = props;

  const mappedAvailableLanguages = availableDORLanguages
    .map((language) => ({ key: language, value: languageToLabel[language] || language.toUpperCase() }))
    .sort((a, b) => a.value.localeCompare(b.value));

  if (type === 'dor-signing-link') {
    return <DORSigningLinkButton languages={mappedAvailableLanguages} shipmentId={shipmentId} />;
  }

  return <DownloadDORButton languages={mappedAvailableLanguages} shipmentId={shipmentId} trackingCode={trackingCode} />;
}

DORButtonDropdown.propTypes = {
  shipmentId: PropTypes.number,
  trackingCode: PropTypes.string,
  type: PropTypes.string,
  availableDORLanguages: PropTypes.array,
};

export default React.memo(DORButtonDropdown);
