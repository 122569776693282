import React, { useState } from 'react';

function useClaimsCenterSearchOptions() {
  const [searchFilters, setSearchFilters] = useState({
    statuses: [],
    types: [],
    shipmentTypes: [],
  });

  const [sortBy, setSortBy] = useState('createdAt');
  const [sortDir, setSortDir] = useState('DESC');

  return {
    searchFilters,
    setSearchFilters,
    sortBy,
    setSortBy,
    sortDir,
    setSortDir,
  };
}

export default useClaimsCenterSearchOptions;
