import { useMemo } from 'react';

/**
 * @typedef Result
 * @property {boolean} isNon7sCarrier
 * @property {Array} carrierOptions
 * @property {Array} carrierCountryOptions
 * @property {string} type
 */

/**
 * @param {string} [carrierCode]
 * @param {string} [carrierCountryCode]
 * @param {Array<ShopClaimCarrier>} carriers
 * @return {Result} result
 */
export function useClaimCarrierSelectOptions({ carrierCode, carrierCountryCode, carriers, type }) {
  const filteredCarriers = useMemo(() => {
    if (type === 'cash_on_delivery') {
      return carriers.filter((carrier) => carrier.isNon7s);
    }

    return carriers;
  }, [carriers, type]);

  const carrierOptions = useMemo(
    () => getUniqueCarriers(filteredCarriers).map(({ name, code }) => ({ label: name || code, value: code })),
    [filteredCarriers]
  );

  const carrierCountryOptions = useMemo(() => getCarrierCountryOptions(carrierCode, filteredCarriers), [
    filteredCarriers,
    carrierCode,
  ]);

  const carrier = useMemo(
    () => filteredCarriers.find(({ code, countryCode }) => code === carrierCode && countryCode === carrierCountryCode),
    [filteredCarriers, carrierCode, carrierCountryCode]
  );

  return {
    isNon7sCarrier: carrier?.isNon7s ?? null,
    carrierOptions,
    carrierCountryOptions,
  };
}

function getUniqueCarriers(carriers) {
  return [...new Map(carriers.map((carrier) => [carrier.code, carrier])).values()];
}

function getCarrierCountryOptions(carrierCode, carriers) {
  return carriers
    .filter(({ code }) => code === carrierCode)
    .map((carrier) => ({ label: carrier.countryName, value: carrier.countryCode }));
}
