import { createMachine } from 'xstate';
import {
  isClaimWithMailboxDeliveryWarning,
  isClaimOutsideOfDeadline,
  isWarehouseDeliveryDateRequired,
  isManual,
  isNonManual,
  isClaimWithMissingTrackingData,
  isInvestigationClaim,
  isWholeDamageClaim,
  isPartialDamageClaim,
  isMissingItemClaim,
  isClaimsCenter,
  isShipmentDelivered,
  isCashOnDeliveryClaim,
} from '../../parcel-finder/claims/claimsHelpers';

export default createMachine({
  key: 'submit-claim',
  strict: true,
  initial: 'choose-claim-type',
  states: {
    'choose-claim-type': {
      on: {
        SELECT_DAMAGE: 'choose-damage-type',
        SELECT_INVESTIGATION: 'choose-entry-point',
        SELECT_MISSING_ITEM: 'choose-entry-point',
        SELECT_CASH_ON_DELIVERY: 'choose-entry-point',
      },
    },
    'choose-damage-type': {
      on: {
        SELECT_PARTIAL_DAMAGE: 'choose-entry-point',
        SELECT_WHOLE_DAMAGE: 'choose-entry-point',
      },
    },
    'choose-entry-point': {
      always: [
        {
          target: 'search-claim-info',
          cond: isClaimsCenter,
        },
        {
          target: 'dispatch-claim-type',
        },
      ],
    },
    'search-claim-info': {
      on: {
        NEXT: 'dispatch-claim-type',
      },
    },
    'dispatch-claim-type': {
      always: [
        {
          target: 'investigation',
          cond: isInvestigationClaim,
        },
        {
          target: 'whole-damage',
          cond: isWholeDamageClaim,
        },
        {
          target: 'partial-damage',
          cond: isPartialDamageClaim,
        },
        {
          target: 'missing-item',
          cond: isMissingItemClaim,
        },
        {
          target: 'cash-on-delivery',
          cond: isCashOnDeliveryClaim,
        },
      ],
    },
    investigation: {
      initial: 'check-shipment',
      states: {
        'check-shipment': {
          always: [
            {
              target: 'investigation-delivered-to-mailbox',
              cond: isClaimWithMailboxDeliveryWarning,
            },
            {
              target: 'deadline-shipping-info',
              cond: isClaimWithMissingTrackingData,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'shipping-info',
              cond: isNonManual,
            },
            {
              target: 'deadline-shipping-info',
            },
          ],
        },
        'investigation-delivered-to-mailbox': {
          on: {
            NEXT: [
              {
                target: 'outside-of-deadline',
                cond: isClaimOutsideOfDeadline,
              },
              {
                target: 'shipping-info',
                cond: isNonManual,
              },
              {
                target: 'deadline-shipping-info',
              },
            ],
          },
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: [
              {
                target: 'investigation-delivery-info',
                cond: isShipmentDelivered,
              },
              {
                target: 'invoice-info',
              },
            ],
          },
        },
        'investigation-delivery-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'whole-damage': {
      initial: 'check-shipment',
      states: {
        'check-shipment': {
          always: [
            {
              target: 'warehouse-delivery-confirmation-info',
              cond: isWarehouseDeliveryDateRequired,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'deadline-shipping-info',
              cond: isClaimWithMissingTrackingData,
            },
            {
              target: 'shipping-info',
              cond: isNonManual,
            },
            {
              target: 'deadline-shipping-info',
            },
          ],
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'whole-damage-info',
          },
        },
        'whole-damage-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'partial-damage': {
      initial: 'check-shipment',
      states: {
        'check-shipment': {
          always: [
            {
              target: 'warehouse-delivery-confirmation-info',
              cond: isWarehouseDeliveryDateRequired,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'deadline-shipping-info',
              cond: isClaimWithMissingTrackingData,
            },
            {
              target: 'shipping-info',
              cond: isNonManual,
            },
            {
              target: 'deadline-shipping-info',
            },
          ],
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'partial-damage-info',
          },
        },
        'partial-damage-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'missing-item': {
      initial: 'check-shipment',
      states: {
        'check-shipment': {
          always: [
            {
              target: 'warehouse-delivery-confirmation-info',
              cond: isWarehouseDeliveryDateRequired,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'deadline-shipping-info',
              cond: isClaimWithMissingTrackingData,
            },
            {
              target: 'shipping-info',
              cond: isNonManual,
            },
            {
              target: 'deadline-shipping-info',
            },
          ],
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'warehouse-delivery-confirmation-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: 'missing-item-info',
          },
        },
        'missing-item-info': {
          on: {
            NEXT: 'invoice-info',
          },
        },
        'invoice-info': {
          type: 'final',
        },
      },
    },
    'cash-on-delivery': {
      initial: 'check-shipment',
      states: {
        'check-shipment': {
          always: [
            {
              target: 'deadline-shipping-info',
              cond: isClaimWithMissingTrackingData,
            },
            {
              target: 'outside-of-deadline',
              cond: isClaimOutsideOfDeadline,
            },
            {
              target: 'shipping-info',
              cond: isNonManual,
            },
            {
              target: 'deadline-shipping-info',
            },
          ],
        },
        'deadline-shipping-info': {
          on: {
            NEXT: 'manual-deadline-check',
          },
        },
        'manual-deadline-check': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'outside-of-deadline': {
          on: {
            NEXT: 'shipping-info',
          },
        },
        'shipping-info': {
          on: {
            NEXT: [
              {
                target: 'proof-of-payment',
                cond: (claim) => !isNonManual(claim),
              },
              {
                target: 'cash-on-delivery-payment-info',
              },
            ],
          },
        },
        'proof-of-payment': {
          on: {
            NEXT: 'cash-on-delivery-payment-info',
          },
        },
        'cash-on-delivery-payment-info': {
          type: 'final',
        },
      },
    },
  },
});
