import React from 'react';

import SelectorWrapper from './common/SelectorWrapper';
import TypeButton from './common/TypeButton';
import ClaimStepContainer from './common/ClaimStepContainer';
import Column from '../../common/layout/Column';
import Row from '../../common/Row';

import DamageIcon from '../../../icons/damage.svg';
import InvestigationIcon from '../../../icons/investigation.svg';
import MissingItemIcon from '../../../icons/missing-item.svg';
import CashOnDeliveryIcon from '../../../icons/cash-on-delivery.svg';

import './ChooseClaimTypeStep.scss';

function ChooseClaimTypeStep(props) {
  const {
    onClickDamage,
    onClickInvestigation,
    onClickMissingItem,
    onClickCashOnDelivery,
    isNonInvestigationFlowsDisabled,
    isCashOnDeliveryDisabled,
    cashOnDeliveryWarningMessage,
    isCashOnDeliveryFeatureEnabled,
    ...restProps
  } = props;

  return (
    <ClaimStepContainer {...restProps}>
      <SelectorWrapper>
        <Column spacing="medium">
          <Row spacing={24}>
            <TypeButton
              icon={DamageIcon}
              title="Damage"
              description="CLAIM_DAMAGE_CHOICE_DESCRIPTION"
              onClick={onClickDamage}
              disabled={isNonInvestigationFlowsDisabled}
              warningMessage="DISABLE_DAMAGE_FLOW_DESC"
            />
            <TypeButton
              icon={InvestigationIcon}
              title="Investigation"
              description="CLAIM_INVESTIGATION_CHOICE_DESCRIPTION"
              onClick={onClickInvestigation}
            />
          </Row>
          {isCashOnDeliveryFeatureEnabled && (
            <Row spacing={24}>
              <TypeButton
                icon={MissingItemIcon}
                title="Missing item"
                description="CLAIM_MISSING_ITEM_CHOICE_DESCRIPTION"
                onClick={onClickMissingItem}
                disabled={isNonInvestigationFlowsDisabled}
                warningMessage="DISABLE_MISSING_ITEM_FLOW_DESC"
              />
              <TypeButton
                icon={CashOnDeliveryIcon}
                title="Cash on delivery"
                description="CLAIM_CASH_ON_DELIVERY_CHOICE_DESCRIPTION"
                onClick={onClickCashOnDelivery}
                disabled={isCashOnDeliveryDisabled}
                warningMessage={cashOnDeliveryWarningMessage}
              />
            </Row>
          )}
          {!isCashOnDeliveryFeatureEnabled && (
            <TypeButton
              icon={MissingItemIcon}
              title="Missing item"
              description="CLAIM_MISSING_ITEM_CHOICE_DESCRIPTION"
              onClick={onClickMissingItem}
              disabled={isNonInvestigationFlowsDisabled}
              warningMessage="DISABLE_MISSING_ITEM_FLOW_DESC"
            />
          )}
        </Column>
      </SelectorWrapper>
    </ClaimStepContainer>
  );
}

export default React.memo(ChooseClaimTypeStep);
