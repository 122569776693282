import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Trans } from 'react-i18next';

import CashOnDeliveryStepContainer from './CashOnDeliveryStepContainer';
import SidePanelContentHeader from '../../../common2/side-panel/SidePanelContentHeader';
import Column from '../../../common/layout/Column';
import Uploader from '../../../common/Uploader';
import RichLocale from '../../../common2/RichLocale';
import InputHelpLink from '../InputHelpLink';

import { useYupValidation } from '../../../../hooks/useYupValidation';
import useOnFormChange from '../../../../hooks/useOnFormChange';

const schema = yup.object().shape({
  proofOfConsumerPayment: yup.array().label('Proof of consumer payment'),
});

function CashOnDeliveryProofOfPaymentStep(props) {
  const { claim, onNext, onChange } = props;
  const { validate, errors } = useYupValidation(schema);
  const [isDisabled, setIsDisabled] = useState(false);
  const onInputChange = useOnFormChange(onChange);

  const onClickNext = () => {
    if (validate(claim)) {
      onNext();
    }
  };

  const uploaderHelpLinkElement = (
    // eslint-disable-next-line max-len
    <InputHelpLink to="https://support.portal.sevensenders.com/support/solutions/articles/15000048062-submission-document-requirements">
      Proof of consumer payment requirements
    </InputHelpLink>
  );

  return (
    <CashOnDeliveryStepContainer {...props} onNext={onClickNext} isDisabled={isDisabled}>
      <SidePanelContentHeader>Proof of consumer payment</SidePanelContentHeader>
      <Column spacing="medium">
        <RichLocale ink>PROOF_OF_PAYMENT_EXPLANATION</RichLocale>
        <Uploader
          name="proofOfConsumerPayment"
          files={claim.proofOfConsumerPayment}
          label="Proof of consumer payment"
          errorWithTranslation={errors.proofOfConsumerPayment}
          allowedFileTypes={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
          helpText="CLAIMS_INVOICE_UPLOAD_HELP_TEXT"
          onChange={onInputChange}
          onBusy={setIsDisabled}
          maxNumberOfFiles={10}
          topRightElement={uploaderHelpLinkElement}
        />
      </Column>
    </CashOnDeliveryStepContainer>
  );
}

CashOnDeliveryProofOfPaymentStep.propTypes = {
  claim: PropTypes.object,
  onChange: PropTypes.func,
};

export default React.memo(CashOnDeliveryProofOfPaymentStep);
