import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ButtonDropdown from '../../../common2/ButtonDropdown/ButtonDropdown';
import MenuItem from '../../../common2/DropdownButton/MenuItem';
import Locale from '../../../Translate/Locale';
import container from '../../../../container';
import useNotifications from '../../../../hooks/useNotifications';
import DownloadIcon from '../../../../icons/download-csv.svg';

function DownloadDORButton(props) {
  const { languages, shipmentId, trackingCode } = props;
  const { showError } = useNotifications();

  const downloadDOR = useCallback(
    async (language) => {
      try {
        const response = await container.touchpointsApi.downloadDor(shipmentId, language);
        const blob = await response.blob();
        const filename = `${trackingCode}_${language}_dor.pdf`;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        showError('There was an error downloading the file');
      }
    },
    [shipmentId, trackingCode, showError]
  );
  return (
    <ButtonDropdown icon={DownloadIcon} text={'Download DOR'}>
      {languages.map(({ key: language, value: label }) => (
        <MenuItem key={language} onClick={() => downloadDOR(language)}>
          <Locale>{label}</Locale>
        </MenuItem>
      ))}
    </ButtonDropdown>
  );
}

DownloadDORButton.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.string })),
  shipmentId: PropTypes.number,
  trackingCode: PropTypes.string,
};

export default React.memo(DownloadDORButton);
